var wipwrp = window.wipwrp || {};

wipwrp.register = (function($,mzr,doc,win) {

    var self = {};

    self.init = function(){
        wipwrp.commons.setInputParent('.control-group');
        wipwrp.utils.countLetters('.count-field');
        wipwrp.utils.checkLikeRadio();
        wipwrp.utils.uppercaseControl('[data-textcontrol="uppercase"]');
        wipwrp.utils.lowercaseControl('[data-textcontrol="lowercase"]');
        wipwrp.commons.relationField();
        wipwrp.autocomplete.init();
        wipwrp.autocomplete.init({
            selector: ".autocomplete-prefisso",
            valueinfield: true
        });

        _setParsley();
        wipwrp.accordion.init({
            multivisible: true
        });
        //_recaptcha();
    }

    function _recaptcha() {
        $('.recaptcha').on('submit', function(e){
            if (!wipwrp.utils.recaptchaCalculated) {
                e.preventDefault();
                wipwrp.utils.addRecaptcha($(this));
            }
        });
    }

    function _setParsley(){
        $('#form_registra').on('change','#nazione_azienda_value', function(e){
            if($('#nazione_azienda_value').val() == 'ITA'){
                $('#forma_giuridica').show();
                $('#tipo_azienda').attr('required',true);
                $('#identificativo_fiscale').val('');
                $('.nazione_no_ita').hide();
                $('.codice_fiscale_eu').hide();
                $('.nazione_ita').show();
                $('#partita_iva').attr('required',true);
                $('#codice_fiscale').attr('required',true);
                $('#identificativo_fiscale').removeAttr('required');
            }else{
                var rappresentata = $('#rappresentata').val();
                $('#tipo_azienda').val('');
                $('#forma_giuridica').hide();
                $('#tipo_azienda').removeAttr('required');
                $('#partita_iva').val('');
                $('#codice_fiscale').val('');
                $('.nazione_ita').hide();
                $('.nazione_no_ita').show();
                $('#partita_iva').removeAttr('required');
                $('#codice_fiscale').removeAttr('required');
                $('#identificativo_fiscale').attr('required',true);
                switch($('#nazione_azienda_value').val()) {
                    case 'AUS':
                        if(rappresentata){
                            $('codice_fiscale').removeAttr('data-parsley-codicefiscale');
                            $('.codice_fiscale_eu').show();
                            $('#codice_fiscale_eu').attr('data-parsley-minlength','5');
                            regexp = '/^[0-9a-zA-Z]{1}[0-9a-zA-Z/.-]{4,}$/';
                        }else {
                            $('#identificativo_fiscale').attr('data-parsley-maxlength', '9');
                            regexp = '/U[0-9]{8}$/';
                        }
                        break;
                    case 'BEL':
                        if(rappresentata){
                            $('codice_fiscale').removeAttr('data-parsley-codicefiscale');
                            $('.codice_fiscale_eu').show();
                            $('#codice_fiscale_eu').attr('data-parsley-minlength','5');
                            regexp = '/^[0-9a-zA-Z]{1}[0-9a-zA-Z/.-]{4,}$/';
                        }else{
                            $('#identificativo_fiscale').attr('data-parsley-maxlength','10');
                            regexp = '/0[0-9]{9}$/';
                        }

                        break;
                    case 'BGR':
                        if(rappresentata){
                            $('codice_fiscale').removeAttr('data-parsley-codicefiscale');
                            $('.codice_fiscale_eu').show();
                            $('#codice_fiscale_eu').attr('data-parsley-minlength','5');
                            regexp = '/^[0-9a-zA-Z]{1}[0-9a-zA-Z/.-]{4,}$/';
                        }else{
                            $('#identificativo_fiscale').attr('data-parsley-minlength','9');
                            $('#identificativo_fiscale').attr('data-parsley-maxlength','10');
                            regexp = '/[0-9]{9,10}$/';
                        }
                        break;
                    case 'CYP':
                        if(rappresentata){
                            $('codice_fiscale').removeAttr('data-parsley-codicefiscale');
                            $('.codice_fiscale_eu').show();
                            $('#codice_fiscale_eu').attr('data-parsley-minlength','5');
                            regexp = '/^[0-9a-zA-Z]{1}[0-9a-zA-Z/.-]{4,}$/';
                        }else{
                            $('#identificativo_fiscale').attr('data-parsley-maxlength','9');
                            regexp = '/[0-9]{8}[a-zA-Z]{1}$/';
                        }

                        break;
                    case 'CZE':
                        if(rappresentata){
                            $('codice_fiscale').removeAttr('data-parsley-codicefiscale');
                            $('.codice_fiscale_eu').show();
                            $('#codice_fiscale_eu').attr('data-parsley-minlength','5');
                            regexp = '/^[0-9a-zA-Z]{1}[0-9a-zA-Z/.-]{4,}$/';
                        }else{
                            $('#identificativo_fiscale').attr('data-parsley-minlength','8');
                            $('#identificativo_fiscale').attr('data-parsley-maxlength','10');
                            regexp = '/[0-9]{8,10}$/';
                        }
                        break;
                    case 'DEU':
                        if(rappresentata){
                            $('codice_fiscale').removeAttr('data-parsley-codicefiscale');
                            $('.codice_fiscale_eu').show();
                            $('#codice_fiscale_eu').attr('data-parsley-minlength','5');
                            regexp = '/^[0-9a-zA-Z]{1}[0-9a-zA-Z/.-]{4,}$/';
                        }else{
                            $('#identificativo_fiscale').attr('data-parsley-maxlength','9');
                            regexp = '/[0-9]{9}$/';
                        }
                        break;
                    case 'DNK':
                        if(rappresentata){
                            $('codice_fiscale').removeAttr('data-parsley-codicefiscale');
                            $('.codice_fiscale_eu').show();
                            $('#codice_fiscale_eu').attr('data-parsley-minlength','5');
                            regexp = '/^[0-9a-zA-Z]{1}[0-9a-zA-Z/.-]{4,}$/';
                        }else{
                            $('#identificativo_fiscale').attr('data-parsley-maxlength','8');
                            regexp = '/[0-9]{8}$/';
                        }
                        break;
                    case 'ESP':
                        if(rappresentata){
                            $('codice_fiscale').removeAttr('data-parsley-codicefiscale');
                            $('.codice_fiscale_eu').show();
                            $('#codice_fiscale_eu').attr('data-parsley-minlength','5');
                            regexp = '/^[0-9a-zA-Z]{1}[0-9a-zA-Z/.-]{4,}$/';
                        }else{
                            $('#identificativo_fiscale').attr('data-parsley-maxlength','9');
                            regexp= '/([0-9][0-9]{7}[a-zA-Z]|[a-zA-Z][0-9]{7}[0-9a-zA-Z])$/';
                        }
                        break;
                    case 'EST':
                        if(rappresentata){
                            $('codice_fiscale').removeAttr('data-parsley-codicefiscale');
                            $('.codice_fiscale_eu').show();
                            $('#codice_fiscale_eu').attr('data-parsley-minlength','5');
                            regexp = '/^[0-9a-zA-Z]{1}[0-9a-zA-Z/.-]{4,}$/';
                        }else{
                            $('#identificativo_fiscale').attr('data-parsley-maxlength','9');
                            regexp = '/[0-9]{9}$/';
                        }
                        break;
                    case 'FIN':
                        if(rappresentata){
                            $('codice_fiscale').removeAttr('data-parsley-codicefiscale');
                            $('.codice_fiscale_eu').show();
                            $('#codice_fiscale_eu').attr('data-parsley-minlength','5');
                            regexp = '/^[0-9a-zA-Z]{1}[0-9a-zA-Z/.-]{4,}$/';
                        }else{
                            $('#identificativo_fiscale').attr('data-parsley-maxlength','8');
                            regexp = '/[0-9]{8}$/';
                        }
                        break;
                    case 'FRA':
                        if(rappresentata){
                            $('codice_fiscale').removeAttr('data-parsley-codicefiscale');
                            $('.codice_fiscale_eu').show();
                            $('#codice_fiscale_eu').attr('data-parsley-minlength','5');
                            regexp = '/^[0-9a-zA-Z]{1}[0-9a-zA-Z/.-]{4,}$/';
                        }else{
                            $('#identificativo_fiscale').attr('data-parsley-maxlength','11');
                            regexp = '/[0-9a-zA-Z]{2}[0-9]{9}$/';
                        }
                        break;
                    case 'GBR':
                        if(rappresentata){
                            $('codice_fiscale').removeAttr('data-parsley-codicefiscale');
                            $('.codice_fiscale_eu').show();
                            $('#codice_fiscale_eu').attr('data-parsley-minlength','5');
                            regexp = '/^[0-9a-zA-Z]{1}[0-9a-zA-Z/.-]{4,}$/';
                        }else{
                            $('#identificativo_fiscale').attr('data-parsley-minlength','5');
                            $('#identificativo_fiscale').attr('data-parsley-maxlength','12');
                            regexp = '/^([0-9]{9}|[0-9]{12}|(GD|HA|gd|ha)[0-9]{3})$/';
                        }
                        break;
                    case 'GRC':
                        if(rappresentata){
                            $('codice_fiscale').removeAttr('data-parsley-codicefiscale');
                            $('.codice_fiscale_eu').show();
                            $('#codice_fiscale_eu').attr('data-parsley-minlength','5');
                            regexp = '/^[0-9a-zA-Z]{1}[0-9a-zA-Z/.-]{4,}$/';
                        }else{
                            $('#identificativo_fiscale').attr('data-parsley-maxlength','9');
                            regexp = '/[0-9]{9}$/';
                        }
                        break;
                    case 'HRV':
                        if(rappresentata){
                            $('codice_fiscale').removeAttr('data-parsley-codicefiscale');
                            $('.codice_fiscale_eu').show();
                            $('#codice_fiscale_eu').attr('data-parsley-minlength','5');
                            regexp = '/^[0-9a-zA-Z]{1}[0-9a-zA-Z/.-]{4,}$/';
                        }else{
                            $('#identificativo_fiscale').attr('data-parsley-maxlength','11');
                            regexp = '/[0-9]{11}$/';
                        }
                        break;
                    case 'HUN':
                        if(rappresentata){
                            $('codice_fiscale').removeAttr('data-parsley-codicefiscale');
                            $('.codice_fiscale_eu').show();
                            $('#codice_fiscale_eu').attr('data-parsley-minlength','5');
                            regexp = '/^[0-9a-zA-Z]{1}[0-9a-zA-Z/.-]{4,}$/';
                        }else{
                            $('#identificativo_fiscale').attr('data-parsley-maxlength','8');
                            regexp = '/[0-9]{8}$/';
                        }
                        break;
                    case 'IRL':
                        if(rappresentata){
                            $('codice_fiscale').removeAttr('data-parsley-codicefiscale');
                            $('.codice_fiscale_eu').show();
                            $('#codice_fiscale_eu').attr('data-parsley-minlength','5');
                            regexp = '/^[0-9a-zA-Z]{1}[0-9a-zA-Z/.-]{4,}$/';
                        }else{
                            $('#identificativo_fiscale').attr('data-parsley-maxlength','9');
                            regexp = '/[A-Z0-9]{8,9}$/';
                        }

                        break;
                    case 'LTU':
                        if(rappresentata){
                            $('codice_fiscale').removeAttr('data-parsley-codicefiscale');
                            $('.codice_fiscale_eu').show();
                            $('#codice_fiscale_eu').attr('data-parsley-minlength','5');
                            regexp = '/^[0-9a-zA-Z]{1}[0-9a-zA-Z/.-]{4,}$/';
                        }else{
                            $('#identificativo_fiscale').attr('data-parsley-minlength','9');
                            $('#identificativo_fiscale').attr('data-parsley-maxlength','12');
                            regexp = '/^([0-9]{9}|[0-9]{12})$/';
                        }
                        break;
                    case 'LUX':
                        if(rappresentata){
                            $('codice_fiscale').removeAttr('data-parsley-codicefiscale');
                            $('.codice_fiscale_eu').show();
                            $('#codice_fiscale_eu').attr('data-parsley-minlength','5');
                            regexp = '/^[0-9a-zA-Z]{1}[0-9a-zA-Z/.-]{4,}$/';
                        }else{
                            $('#identificativo_fiscale').attr('data-parsley-maxlength','8');
                            regexp = '/[0-9]{8}$/';
                        }
                        break;
                    case 'LVA':
                        if(rappresentata){
                            $('codice_fiscale').removeAttr('data-parsley-codicefiscale');
                            $('.codice_fiscale_eu').show();
                            $('#codice_fiscale_eu').attr('data-parsley-minlength','5');
                            regexp = '/^[0-9a-zA-Z]{1}[0-9a-zA-Z/.-]{4,}$/';
                        }else{
                            $('#identificativo_fiscale').attr('data-parsley-maxlength','11');
                            regexp = '/[0-9]{11}$/';
                        }
                        break;
                    case 'MLT':
                        if(rappresentata){
                            $('codice_fiscale').removeAttr('data-parsley-codicefiscale');
                            $('.codice_fiscale_eu').show();
                            $('#codice_fiscale_eu').attr('data-parsley-minlength','5');
                            regexp = '/^[0-9a-zA-Z]{1}[0-9a-zA-Z/.-]{4,}$/';
                        }else{
                            $('#identificativo_fiscale').attr('data-parsley-maxlength','8');
                            regexp = '/[0-9]{8}$/';
                        }
                        break;
                    case 'NLD':
                        if(rappresentata){
                            $('codice_fiscale').removeAttr('data-parsley-codicefiscale');
                            $('.codice_fiscale_eu').show();
                            $('#codice_fiscale_eu').attr('data-parsley-minlength','5');
                            regexp = '/^[0-9a-zA-Z]{1}[0-9a-zA-Z/.-]{4,}$/';
                        }else{
                            $('#identificativo_fiscale').attr('data-parsley-maxlength','12');
                            regexp = '/[0-9a-zA-Z]{9}B[0-9a-zA-Z]{2}$/';
                        }
                        break;
                    case 'POL':
                        if(rappresentata){
                            $('codice_fiscale').removeAttr('data-parsley-codicefiscale');
                            $('.codice_fiscale_eu').show();
                            $('#codice_fiscale_eu').attr('data-parsley-minlength','5');
                            regexp = '/^[0-9a-zA-Z]{1}[0-9a-zA-Z/.-]{4,}$/';
                        }else{
                            $('#identificativo_fiscale').attr('data-parsley-maxlength','10');
                            regexp = '/[0-9]{10}$/';
                        }
                        break;
                    case 'PRT':
                        if(rappresentata){
                            $('codice_fiscale').removeAttr('data-parsley-codicefiscale');
                            $('.codice_fiscale_eu').show();
                            $('#codice_fiscale_eu').attr('data-parsley-minlength','5');
                            regexp = '/^[0-9a-zA-Z]{1}[0-9a-zA-Z/.-]{4,}$/';
                        }else{
                            $('#identificativo_fiscale').attr('data-parsley-maxlength','9');
                            regexp = '/[0-9]{9}$/';
                        }
                        break;
                    case 'ROU':
                        if(rappresentata){
                            $('codice_fiscale').removeAttr('data-parsley-codicefiscale');
                            $('.codice_fiscale_eu').show();
                            $('#codice_fiscale_eu').attr('data-parsley-minlength','5');
                            regexp = '/^[0-9a-zA-Z]{1}[0-9a-zA-Z/.-]{4,}$/';
                        }else{
                            $('#identificativo_fiscale').attr('data-parsley-minlength','2');
                            $('#identificativo_fiscale').attr('data-parsley-maxlength','10');
                            regexp = '/[0-9]{2,10}$/';
                        }
                        break;
                    case 'SVK':
                        if(rappresentata){
                            $('codice_fiscale').removeAttr('data-parsley-codicefiscale');
                            $('.codice_fiscale_eu').show();
                            $('#codice_fiscale_eu').attr('data-parsley-minlength','5');
                            regexp = '/^[0-9a-zA-Z]{1}[0-9a-zA-Z/.-]{4,}$/';
                        }else{
                            $('#identificativo_fiscale').attr('data-parsley-maxlength','10');
                            regexp = '/[0-9]{10}$/';
                        }
                        break;
                    case 'SVN':
                        if(rappresentata){
                            $('codice_fiscale').removeAttr('data-parsley-codicefiscale');
                            $('.codice_fiscale_eu').show();
                            $('#codice_fiscale_eu').attr('data-parsley-minlength','5');
                            regexp = '/^[0-9a-zA-Z]{1}[0-9a-zA-Z/.-]{4,}$/';
                        }else{
                            $('#identificativo_fiscale').attr('data-parsley-maxlength','8');
                            regexp = '/[0-9]{8}$/';
                        }
                        break;
                    case 'SWE':
                        if(rappresentata){
                            $('codice_fiscale').removeAttr('data-parsley-codicefiscale');
                            $('.codice_fiscale_eu').show();
                            $('#codice_fiscale_eu').attr('data-parsley-minlength','5');
                            regexp = '/^[0-9a-zA-Z]{1}[0-9a-zA-Z/.-]{4,}$/';
                        }else{
                            $('#identificativo_fiscale').attr('data-parsley-maxlength','12');
                            regexp = '/[0-9]{12}$/';
                        }
                        break;
                    default:
                        $('.codice_fiscale_eu').hide();
                        $('#label_tax_code').html('TAX CODE<span>*</span>');
                        $('#identificativo_fiscale').attr('data-parsley-minlength','5');
                        regexp = '/^[0-9a-zA-Z]{1}[0-9a-zA-Z/.-]{4,}$/';
                        break;
                }
                $('#identificativo_fiscale').attr('data-parsley-pattern',regexp);
            }
        });

        //istanzio anche all'inizio per controllare i campi in blur
        $('form#form_registra').parsley();
        $('#form_registra_submit').on('click', function (e) {
            if($('#nazione_azienda_value').val() == 'ITA'){
                if($('#partita_iva').val()!= ''){
                    $('#codice_fiscale').removeAttr('required');
                }else{
                    $('#codice_fiscale').attr('required',true);
                }
                if($('#codice_fiscale').val() != ''){
                    $('#partita_iva').removeAttr('required');
                }else{
                    $('#partita_iva').attr('required',true);
                }
            }

            if ($('form#form_registra').parsley().validate() === true){
                return true;
            }else{
                return false;
            }
        });
    }


    return self;
})(jQuery,Modernizr, document, window);

